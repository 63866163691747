<template>
  <b-card>

    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img
            ref="callback"
            rounded
            :src="image"
            height="80"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.InputEl.click()"
        >
          Carregar
        </b-button>
        <input
          ref="InputEl"
          type="file"
          class="d-none"
          hidden
          @change="uploadImg"
        >
        <!--/ upload button -->

        <!-- reset -->
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          size="sm"
          class="mb-75 mr-75"
        >
          Resetar
        </b-button>
        <!--/ reset -->
        <b-card-text>Somente imagens no formato JPG, GIF or PNG. Tamanho máximo de 800kB</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group
            label="Nome"
            label-for="account-username"
          >
            <b-form-input
              v-model="general.nome"
              placeholder="Username"
              name="username"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="CPF"
            label-for="account-e-mail"
          >
            <b-form-input
              v-model="general.cpf"
              name="email"
              placeholder="Email"
              disabled
            />

          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="E-mail"
            label-for="account-e-mail"
          >
            <b-form-input
              v-model="general.usuario_empresas.user.email"
              name="email"
              placeholder="Email"
              disabled
            />

          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Função"
            label-for="account-company"
          >
            <b-form-input
              v-model="general.funcao.nome"
              name="company"
              placeholder="Company name"
              disabled
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import axios from '../../../axios-auth'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  directives: {
    Ripple,
  },
  props: {
    general: {
      type: Object,
      default: () => {},
    },
    image: {
      type: String,
      default: () => {},
    },
  },
  setup() {
    const InputEl = ref(null)
    const callback = ref(null)

    return {
      InputEl,
      callback,
    }
  },
  data() {
    return {
      profileFile: '',
    }
  },
  methods: {
    async uploadImg(event) {
      const formData = new FormData()
      formData.append('image', event.target.files[0])
      await axios.post('api/v1/users/uploadFoto', formData, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        this.$router.go()
        if (res) {
          this.$swal({
            title: 'Imagem de perfil atualizada',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
          })
        }
      })
        .catch(() => {
          this.$swal({
            title: 'ERRO',
            text: 'Algo deu errado!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },
  },
}
</script>
