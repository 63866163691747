<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Geral</span>
      </template>

      <usuario-geral
        :general="dataUser"
        :image="profileFile"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Mudar Senha</span>
      </template>

      <usuario-senha
        :general="dataUser"
      />
    </b-tab>
    <!--/ change password tab -->
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import UsuarioGeral from './UsuarioGeral.vue'
import UsuarioSenha from './UsuarioSenha.vue'
// import AccountSettingInformation from './AccountSettingInformation.vue'
// import AccountSettingSocial from './AccountSettingSocial.vue'
// import AccountSettingNotification from './AccountSettingNotification.vue'
import axios from '../../../axios-auth'

export default {
  components: {
    BTabs,
    BTab,
    UsuarioGeral,
    UsuarioSenha,
    // AccountSettingInformation,
    // AccountSettingSocial,
    // AccountSettingNotification,
  },
  data() {
    return {
      profileFile: '',
      dataUser: {},
    }
  },
  created() {
    this.funcionarioId = localStorage.getItem('funcionarioId')
    this.getImage()
    this.getInfoFuncionario()
  },
  methods: {
    async getImage() {
      await axios.get(`api/v1/users/exibirFoto/${this.funcionarioId}`, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        this.profileFile = res.data.dados.image
      })
    },
    async getInfoFuncionario() {
      await axios.get(`api/v1/funcionarios/edit/${this.funcionarioId}`, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        this.dataUser = res.data.dados.funcionario
      })
    },
  },
}
</script>
